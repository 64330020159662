.dashboard-container {
  max-width: 1140px;
  margin: 0 auto !important;
}

@media screen and (max-width: 576px) {
  .dashboard-container {
    margin: 0 10px !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 1200px) {
  .dashboard-container {
    margin: 0 20px !important;
  }
}

.react-datepicker-wrapper {
  margin: 0 10px;
}

.react-datepicker__input-container > input {
  max-width: 110px;
  height: 34px;
  outline: none;
  text-align: center;
}

.header-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 20px;
}

.filter-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.button-group,
.add-button {
  margin: 10px;
}

.inactive-button,
.inactive-button:hover {
  background-color: white !important;
  color: black !important;
}

.main-loader {
  display: flex !important;
  margin: 100px auto;
  width: 45px !important;
  height: 45px !important;
  color: #007bff !important;
}

.pdf-icon {
  cursor: pointer !important;
}

.header-center {
  text-align: center;
}

.footer-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

.pagination-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.custom-select {
  width: 70px !important;
  margin: 5px !important;
}

.pagination-text {
  margin: 10px;
}

.pagination-button {
  margin: 5px;
  width: 80px;
}

@media screen and (max-width: 576px) {
  .react-datepicker-wrapper {
    margin: 10px;
  }
  .button-group,
  .add-button {
    margin: 0 10px;
  }
}
