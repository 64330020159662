.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  width: 450px;
  max-width: 450px;
  background-color: #bdd8f5;
  border-radius: 10px;
  padding: 40px 40px 45px;
  margin-top: 80px;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  border-radius: 15px;
  transition: all 0.3s;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 576px) {
  .login-form {
    padding: 40px 20px 45px;
  }
}
